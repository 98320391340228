import React, { useEffect, useState } from 'react'
// html-react-parser
// import parse from 'html-react-parser';
// graphql
import { useQuery } from '@apollo/client';
import { GET_PAGE_DATA, GET_LAST_RESULT, GET_SEASON_RANKING, GET_FIRST_FOUR_SPONSORS, GET_NEXT_UPCOMING_EVENT } from '../../graphql/queries';
// styles
import './Home.scss'
// pages
import Error from '../Error';
// helmet
import { Helmet } from 'react-helmet';
// components
import Loading from '../../components/Elements/Loading';
import Button from '../../components/Basics/Button'
import Redirect from '../../components/Basics/Redirect'
import Hero from '../../components/Elements/Hero';
import AllSponsors from '../../components/Elements/AllSponsors';
// utils
import { formatDate } from '../../utils/dateUtils';
import { Link } from 'react-router-dom';

export default function Home() {
  const slug = 'home';

  // --------- STATE --------- //

  const [todayDate, setTodayDate] = useState('');

  useEffect(() => {
    setTodayDate(new Date().toISOString());
  }, []);

  // --------- QUERY FOR THE PAGE DATA --------- //

  const { loading, error, data } = useQuery(GET_PAGE_DATA, {
    variables: { slug },
  });
  // Check if data is loaded before accessing seasonSlug
  const seasonSlug = data?.page?.season?.slug;

  const { loading: loadingLastResult, error: errorLastResult, data: dataLastResult } = useQuery(GET_LAST_RESULT);
  const { loading: loadingRanking, error: errorRanking, data: dataRanking } = useQuery(GET_SEASON_RANKING, {
    variables: { seasonSlug },
    skip: !seasonSlug, // Skip this query if seasonSlug is not available
  });
  const { loading: loadingNextEvent, error: errorNextEvent, data: dataNextEvent } = useQuery(GET_NEXT_UPCOMING_EVENT, {
    variables: { todayDate },
    skip: !todayDate, // Skip this query if todayDate is not available
  });
  const { loading: loadingSponsors, error: errorSponsors, data: dataSponsors } = useQuery(GET_FIRST_FOUR_SPONSORS);
  if (loading || loadingLastResult || loadingRanking || loadingNextEvent || loadingSponsors) return <Loading />;
  if (error || errorLastResult || errorRanking || errorNextEvent || errorSponsors) return <Error code='502' text='Invalid response. Please try again later.'/>;

  // --------- PAGE DATA --------- //

  const pageData = data.page;
  const heroSection = pageData.heroSection;
  const lastResult = dataLastResult.results[0];
  const ranking = dataRanking.rankings[0];
  const event = dataNextEvent?.events?.[0];
  const sponsors = dataSponsors.sponsors;
  const matchDate = formatDate(lastResult.matchDate);

  // --------- RENDER --------- //

  return (
    <>
      {/* head data */}
      <Helmet>
        <title>{pageData.title}</title>
        <meta name='description' content={pageData.pageDescription}/>
      </Helmet>

      {/* hero section */}
      <Hero title={heroSection.title} subtitle={heroSection.subtitle} image={heroSection.image.url} />

      {/* announcement */}
      <div className='announcement'>
        <div className='announcement__content'>
          <p>Onze beloften ploeg is op zoek naar nieuwe spelers! Geïnteresseerd? <Link to="/contact">Laat het weten</Link>.</p>
          {/* <p>Iedereen welkom op onze 1e footlunch van het seizoen! Zondag 22 september, aanvang om 11u30 stipt. <Link to="/contact">Meer weten</Link>? Reserveren is verplicht bij Herbert Van Hoefs via 0498108718.</p> */}
        </div>
      </div>

      {/* last results of a match */}
      <div className='last-results'>
        <div className='container'>
          <div className='container__header'>
            <h3>Laatste resultaat</h3>
            <Button label='Alle uitslagen' link='/uitslagen' />
          </div>
          <div className='last-results__hightlight'>
            <div className='highlight__home'><h2>{lastResult.homeTeam.title}</h2></div>
            <div className='highlight__score'>
              <div>
                <p>{lastResult.competition.title}</p>
                <p>{matchDate}</p>
              </div>
              <h1>{lastResult.homeTeamScore} - {lastResult.awayTeamScore}</h1>
              {lastResult.comment && <p className='highlight__comment'>{lastResult.comment}</p>}
              <p className='highlight__teams'>{lastResult.homeTeam.title} - {lastResult.awayTeam.title}</p>
            </div>
            <div className='highlight__away'><h2>{lastResult.awayTeam.title}</h2></div>
          </div>
        </div>
      </div>

      {/* two parts: ranking and upcoming event */}
      <div className='two-parts container'>
        <div className='ranking'>
          <div className='container__header'>
            <h3>Klassement</h3>
            <Redirect link={ranking.link} label='Volledige klassement' target='_blank' />
          </div>
          <div className='box ranking__content'>
            <h1 className='ranking__place'>{ranking.ranking}e Plaats</h1>
            <div className='ranking__matches'>
              <div className='column played'>
                <span className='column__title'>M</span>
                <p>{ranking.playedMatches}</p>
              </div>
              <div className='column wins'>
                <span className='column__title'>W</span>
                <p>{ranking.won}</p>
              </div>
              <div className='column draws'>
                <span className='column__title'>G</span>
                <p>{ranking.draw}</p>
              </div>
              <div className='column losses'>
                <span className='column__title'>V</span>
                <p>{ranking.lost}</p>
              </div>
              <div className='column points'>
                <span className='column__title'>PTN</span>
                <h2>{ranking.points}</h2>
              </div>
            </div>
            <div className='ranking__goals'>
              <svg className='trophy' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 103.117 103.117"><g data-name="Group 2"><path fill="#f7d24b" stroke="#fdf9ea" strokeWidth="4" d="M84.6 101.117H18.519V76.338h8.673a23.43 23.43 0 0 1 7.434-13.629 30.1 30.1 0 0 1-9.086-11.151h-2.891C10.673 51.558 2 43.3 2 32.148V10.26h20.649V2h57.818v8.26h20.65v21.888c0 11.151-8.673 19.41-20.649 19.41h-2.892A28.183 28.183 0 0 1 68.491 62.3a23.477 23.477 0 0 1 7.434 14.042h8.26v24.775Zm-57.818-8.26h49.556V84.6H26.779Zm8.673-16.519h32.21a19.122 19.122 0 0 0-7.021-9.912 27.426 27.426 0 0 1-18.171 0 19.122 19.122 0 0 0-7.021 9.912ZM30.909 10.26v28.909a20.65 20.65 0 0 0 41.3 0V10.26ZM80.054 43.3c7.434 0 12.8-4.543 12.8-11.151v-13.63H80.467v20.65a14.049 14.049 0 0 1-.413 4.131ZM10.26 18.519v13.629c0 6.608 5.369 11.151 12.39 11.151h.413c0-1.239-.413-2.891-.413-4.13v-20.65Z" data-name="Path 42"/></g></svg>
              <div className='column for'>
                <span className='column__title'>DV</span>
                <p>{ranking.goalsFor}</p>
              </div>
              <div className='column against'>
                <span className='column__title'>DT</span>
                <p>{ranking.goalsAgainst}</p>
              </div>
              <div className='column difference'>
                <span className='column__title'>DS</span>
                <h2>{ranking.goalSaldo}</h2>
              </div>
            </div>
          </div>
        </div>

        <div className='event'>
          <div className='container__header'>
            <h3>Aankomende evenement</h3>
            <Redirect link='https://www.facebook.com/p/RFC-Doggen-100057054506591/' label='Facebook pagina' target='_blank' />
          </div>
          {event ? (
            <div className='box event__content'>
              <div className='event__info'>
                <h2>{event.title}</h2>
                <p>{event.dateDescription}</p>
              </div>
              <img src={event.image.url} alt='event' />
            </div>
          ) : (
            <p>No upcoming event</p>
          )}
        </div>
      </div>

      {/* season pass */}
      <div className='container'>
        <div className='container__header'>
          <h3>Abonnement {pageData.season.title}</h3>
        </div>
        <div className='season-pass'>
          <p className='paragraph'>Voor slechts 65 euro geniet je van 15 thuiswedstrijden!</p>
          <p className='paragraph'>Waar te koop?</p>
          <p className='list__item'>Bij Eddy & René</p>
          <p className='list__item paragraph'>Aan de ingang bij elke thuiswedstrijd</p>
          <p>Mis geen enkel moment van het nieuwe seizoen en verzeker je van een plaats!</p>
        </div>
      </div>

      {/* sponsors */}
      <div className='container'>
        <div className='container__header'>
          <h3>Sponsors</h3>
          <Button label='Alle sponsors bekijken' link='/sponsors' />
        </div>
        <AllSponsors sponsors={sponsors} />
      </div>
    </>
  )
}
